<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Julio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div>
    <TablaStructure :ruta="ruta" :sincEnterprise="sincEnterprise" :nombreItem="nombreItem" :nombreId="nombreId"/>
  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>

import TablaStructure  from "../../../../components/human-talent/TablaStructure.vue";

export default {
  name: "NivelesJerarquicos",
  props: {},
  components: { TablaStructure },
  data() {
    return {
      ruta: "msa-hr/api/nivel-jerarquico",
      sincEnterprise:[1],
      nombreItem: `Nivel Jerarquico`,
      nombreId: "idNivelJerarquico"
    };
  },
};

</script>